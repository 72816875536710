import React from 'react'

import SearchListTemplate from '../../components/templates/SearchListTemplate'

const LocationAds = () => {
  const title = 'Trouver Mon Local Pro - Nos Annonces à louer'
  const description = 'Tous les locaux professionnels du 2 au 5 pièces à louer.'

  return (
    <SearchListTemplate
      title={title}
      description={description} />
  )
}

export default LocationAds
